var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vertical-menu"},[_c('simplebar',{ref:"currentMenu",staticClass:"h-100",attrs:{"id":"my-element"}},[_c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[_vm._l((_vm.menuItems),function(item){return [(item.isTitle)?_c('li',{key:'z' + item.id,staticClass:"menu-title"},[_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")]):_vm._e(),(!item.isTitle && !item.isLayout)?_c('li',{key:`y` + item.id},[((!item.roleLv1 && _vm.hasItems(item)) || (item.roleLv1 && _vm.checkPermissLv(item.roleLv1)))?_c('a',{staticClass:"is-parent",class:{
                                'has-arrow': !item.badge,
                                'has-dropdown': item.badge
                            },attrs:{"href":"javascript:void(0);"}},[(item.icon)?_c('i',{class:`bx ${item.icon}`}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t(item.label)))]),(item.badge)?_c('span',{class:`badge badge-pill badge-${item.badge.variant} float-right`},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),((!item.role && !_vm.hasItems(item)) || (item.role && _vm.checkPermiss(item.role)))?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":item.link}},[(item.icon)?_c('i',{class:`bx ${item.icon}`}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t(item.label)))]),(item.badge)?_c('span',{class:`badge badge-pill badge-${item.badge.variant} float-right`},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),(_vm.hasItems(item))?_c('ul',{staticClass:"sub-menu",class:{
                                'custome__menu--client--order': item.id === 12
                            },attrs:{"aria-expanded":"false"}},[_vm._l((item.subItems),function(subitem,index){return [(subitem.isTitle)?_c('li',{key:`kl_` + index,staticClass:"menu-title px-0",staticStyle:{"padding":"0 !important"}},[_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(_vm.$t(subitem.label)))])]):_vm._e(),(!subitem.isTitle && !subitem.isLayout)?_c('li',{key:`k_` + index},[(
                                            (!subitem.role && !_vm.hasItems(subitem)) ||
                                            (subitem.role && _vm.checkPermiss(subitem.role) && !_vm.hasItems(subitem))
                                        )?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":subitem.link}},[_vm._v(_vm._s(_vm.$t(subitem.label)))]):_vm._e(),(
                                            (!subitem.roleLv2 && _vm.hasItems(subitem)) ||
                                            (subitem.roleLv2 && _vm.checkPermissLv(subitem.roleLv2) && _vm.hasItems(subitem))
                                        )?_c('a',{staticClass:"side-nav-link-a-ref has-arrow",style:(`color: ${subitem.color}`),attrs:{"href":"javascript:void(0);"}},[_vm._v(_vm._s(_vm.$t(subitem.label)))]):_vm._e(),(_vm.hasItems(subitem))?_c('ul',{staticClass:"sub-menu mm-collapse",class:{
                                            'collap--custom': index === 0
                                        },attrs:{"aria-expanded":"false"}},_vm._l((subitem.subItems),function(subSubitem,index){return _c('li',{key:'u' + index},[(
                                                    (!subSubitem.role && !_vm.hasItems(subSubitem)) ||
                                                    (subSubitem.role && _vm.checkPermiss(subSubitem.role) && !_vm.hasItems(subSubitem))
                                                )?_c('router-link',{staticClass:"side-nav-link-ref",class:{
                                                    'menu__client--order': item.id === 12
                                                },style:(`color: ${subSubitem.color}`),attrs:{"to":subSubitem.link}},[_vm._v(_vm._s(_vm.$t(subSubitem.label)))]):_vm._e(),(
                                                    (!subSubitem.role && _vm.hasItems(subSubitem)) ||
                                                    (subSubitem.role && _vm.checkPermiss(subSubitem.role) && _vm.hasItems(subSubitem))
                                                )?_c('a',{staticClass:"side-nav-link-a-ref has-arrow menu__client--order",style:(`color: ${subSubitem.color} !important`),attrs:{"href":"javascript:void(0);"}},[_vm._v(_vm._s(_vm.$t(subSubitem.label)))]):_vm._e(),(_vm.hasItems(subSubitem))?_c('ul',{staticClass:"sub-menu mm-collapse menu__client--order",attrs:{"aria-expanded":"true"}},_vm._l((subSubitem.subItems),function(subSubSubitem,index){return _c('li',{key:'h' + index},[(
                                                            (!subSubSubitem.role && !_vm.hasItems(subSubSubitem)) ||
                                                            (subSubSubitem.role && _vm.checkPermiss(subSubSubitem.role) && !_vm.hasItems(subSubSubitem))
                                                        )?_c('router-link',{staticClass:"side-nav-link-ref",style:(`color: ${
                                                            _vm.isActive(subSubSubitem.link) ? '#ffff' : subSubitem.color
                                                        } !important; background-color: ${
                                                            _vm.isActive(subSubSubitem.link) ? subSubitem.bg_active : ''
                                                        }!important`),attrs:{"to":subSubSubitem.link}},[_vm._v(_vm._s(_vm.$t(subSubSubitem.label)))]):_vm._e()],1)}),0):_vm._e()],1)}),0):_vm._e()],1):_vm._e()]})],2):_vm._e()],1):_vm._e()]})],2)])])],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }