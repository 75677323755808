<script>
export default {
    methods: {
        handleBreadcrumb(param) {
            return param?.filter((item) => item.text !== '');
        }
    },
    computed: {
        breadcrumbConfig() {
            let title = '';
            let pageItems = [];

            if (!this.$route?.meta?.typeRouterForm) {
                title = this.$route.meta?.titleJP;
                pageItems = this.$route.meta?.pageNames;
            }
            if (this.$route?.meta?.typeRouterForm) {
                if (!this.$route?.query?.id) {
                    title = this.$route.meta?.titleJPAdd;
                    pageItems = this.$route.meta?.pageNamesAdd;
                } else {
                    title = this.$route.meta?.titleJPEdit;
                    pageItems = this.$route.meta?.pageNamesEdit;
                }
            }
            return {
                title,
                pageItems
            };
        }
    }
};
</script>

<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
                <h3 class="mb-0">{{ breadcrumbConfig.title }}</h3>
                <div class="page-title-right">
                    <b-breadcrumb :items="handleBreadcrumb(breadcrumbConfig.pageItems)" class="m-0"></b-breadcrumb>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->
</template>
<style></style>
